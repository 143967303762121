import { motion } from 'framer-motion'
import { HashLink } from 'react-router-hash-link'

import { ReactComponent as Logo } from '../../images/logo.svg'
import { ReactComponent as Stroke } from '../../images/landing/chess-stroke.svg'

const ExpertsSection = () => {
  return (
    <div id='experts' className='flex scroll-m-24 flex-col overflow-hidden bg-zinc-900 text-white'>
      <div className='container my-16 space-y-20 md:my-24 md:space-y-28'>
        <div className='relative flex flex-col items-center'>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              repeatType: 'reverse',
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-8 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:text-6xl lg:text-8xl'
          >
            Team of Experts
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              repeatType: 'reverse',
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-12 text-center text-lg sm:text-2xl'
          >
            Our talented team of professionals has the skills and expertise to help you achieve your financial goals and
            maximize your returns! Our talented team of professionals has the skills and expertise to help you achieve
            your financial goals and maximize your returns.
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              repeatType: 'reverse',
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='rounded-full bg-white px-10 py-3 text-lg font-medium text-black transition-all hover:opacity-95'
          >
            <HashLink to={'#home'}>Join Waitlist</HashLink>
          </motion.div>
        </div>
        <div className='grid grid-cols-12 gap-4'>
          <div className='relative col-span-12 hidden h-[250px] rounded-3xl bg-primary p-6 sm:p-10 lg:col-span-4 lg:block lg:h-full'>
            <motion.img
              src='/img/chess.png'
              alt='chess'
              className='absolute -top-20 right-10 w-[150px] lg:-top-28 lg:w-[230px]'
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', stiffness: 150, damping: 16, delay: 0.15, duration: 0.5 }}
              viewport={{ once: false }}
            />
            <motion.img
              src='/img/chess-shadow.png'
              alt='chess_shadow'
              className='absolute -top-12 right-14 w-[150px] lg:-top-16 lg:right-16 lg:w-[230px]'
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', stiffness: 150, damping: 16, delay: 0.15, duration: 0.5 }}
              viewport={{ once: false }}
            />
            <Stroke className='absolute bottom-8 right-0 text-primary' />
          </div>
          <div className='relative col-span-12 flex flex-col items-start overflow-hidden rounded-3xl bg-white p-6 text-black sm:p-10 lg:col-span-8'>
            <motion.img
              src='/img/dashboard.png'
              alt='dashboard'
              className='absolute left-[360px] top-1/3'
              initial={{ opacity: 0, scale: 0.75 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', stiffness: 150, damping: 16, delay: 0.15, duration: 0.5 }}
              viewport={{ once: false }}
            />
            <Logo className='mb-2 w-64 sm:mb-4' />
            <span className='mb-10 text-xl sm:w-1/2 sm:text-2xl md:w-1/3'>Unlock the power of your capital.</span>
            <HashLink
              to={'#home'}
              className='rounded-full bg-primary px-10 py-3 text-lg font-medium text-white transition-all hover:opacity-95'
            >
              Join Waitlist
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertsSection
