import Footer from '../landing/footer'
import Header from '../landing/header'

const PolicyPage = () => {
  return (
    <div className='flex flex-col bg-zinc-900 font-Open-Sans font-thin text-white'>
      <Header />
      <div className='container mt-32 flex flex-col'>
        <h1 className='mb-4 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:mb-8 sm:text-6xl'>
          Privacy Policy
        </h1>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>1. Introduction</h2>
        <p className='mb-4'>
          Capitavio Limited (the &quot;Company&quot;, “we”), a limited liability company, incorporated under the laws of
          the Republic of Cyprus, with company registration number ΗΕ 390316 and having its registered office at Terra
          Santa 10, Flat/Office 33, Strovolos 2001, Nicosia, Cyprus
        </p>
        <p className='mb-4'>
          This Privacy policy defines the way the Company processes the Personal Data of natural persons while setting
          forth the rights of the Data Subjects. Your Personal Data will be held and processed by the Company.
        </p>
        <p className='mb-6'>
          Data processing may potentially take place at a Group level as provided under the General Data Protection
          Regulation 2016/679 (“GDPR”), where a legitimate interest is justified.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>2. Types of Personal Data Processed</h2>
        <p className='mb-4'>
          The types of Personal Data processed by the Company depend on your relationship with the Company.
        </p>
        <p className='mb-4'>The Company may collect and process any of the following types of Personal Data:</p>
        <p className='mb-4'>
          <i>Identification data including national identification numbers</i> - information that can directly identify
          you such as name, surname, date of birth, gender, place of birth, citizenship
        </p>
        <p className='mb-4'>
          <i>Contact</i> - contact details such as telephone number, home address, work address, and email address.
        </p>
        <p className='mb-4'>
          <i>Financial</i> - information on your personal wealth including your assets, details of the assets, streams
          of incomes, expected incomes, personal financial position, salary, economic status, account numbers, IBAN, and
          other financial information.
        </p>
        <p className='mb-4'>
          <i>Socio-Demographic</i> - this includes details about your work or profession, nationality, education,
          marital status, and where you fit into general social or income groups.
        </p>
        <p className='mb-4'>
          <i>Transactional</i> - details about payments to and from your accounts with the Company and tax information.
        </p>
        <p className='mb-4'>
          <i>Contractual</i> - details about the products or services we provide you with.
        </p>
        <p className='mb-4'>
          <i>Locational</i> - data we get about where you are. Such data may come from your mobile phone, the address
          where you connect a computer to the internet to access our website(s) and platform(s).
        </p>
        <p className='mb-4'>
          <i>Behavioral</i> - details about how you use our products and services.
        </p>
        <p className='mb-4'>
          <i>Technical/Digital</i> - details of the devices and technology you use, your digital activity
        </p>
        <p className='mb-4'>
          <i>Communications</i> - what we learn about you from letters, emails, and conversations between us.
        </p>
        <p className='mb-4'>
          <i>Social Relationships</i> - your family, associates, and other relationships you declare for the purposes of
          our business relationship.
        </p>
        <p className='mb-4'>
          <i>Documentary</i> - details about you that are stored on documents in different formats, or copies of them.
          This could include things like your specimen signature, passport, identity card, driver’s license or birth
          certificate.
        </p>
        <p className='mb-4'>
          <i>Video and sound recordings</i> - sound recordings and video footages recorded by the Company i when you
          voice or video call us or have an online meeting with us.
        </p>
        <p className='mb-6'>
          <i>Special categories of Personal Data</i> - certain types of Personal Data are more sensible and are
          considered special, by law.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>3. Collection of Personal Data</h2>
        <p className='mb-4'>
          We collect personal data that you provide directly by applying for Company`s products, using our website (s),
          terms of usage or privacy policy consent signature or contacting us.
        </p>
        <p className='mb-4'>
          We also collect personal data indirectly - for example through your authorized representatives or
          persons/Organizations introducing you to the Company (i.e., Fiduciary Service Providers, Approved
          Introducers).
        </p>
        <p className='mb-6'>
          We may also collect personal data from other publicly accessible sources - such as the Registrar of Companies,
          the Bankruptcy Archive, international commercial registers or other sources such as government and law
          enforcement agencies, risk intelligence databases or other databases where it is necessary to assess the risk
          undertaken by the Company.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>4. Legal Basis and Purpose of processing Personal Data</h2>
        <h3 className='mb-2 text-lg sm:text-xl'>
          <b>Legal Basis</b>
        </h3>
        <p className='mb-4'>
          The Company will process Personal Data provided that one or more of the following reasons is applicable:
        </p>
        <ol className='mb-6 list-inside space-y-2 [&>li]:pl-4'>
          <li>
            a) To fulfill a contract you have with the Company or to take any steps, at your request, prior to entering
            into a contract with the Company
          </li>
          <li>b) When it is the Company’s legal obligation to process your Personal Data</li>
          <li>
            c) When it is in the legitimate interests of the Company or another person with whom the data are shared
          </li>
          <li>d) When you consent to the use</li>
          <li>e) When it is in the public interest or in the exercise of official authority vested in the Company</li>
        </ol>
        <h3 className='mb-2 text-lg sm:text-xl'>
          <b>Purposes of Processing</b>
        </h3>
        <p className='mb-6'>
          Your Personal Data may be processed based on the following purposes: Provision of services, Comply with
          Anti-Money Laundering (AML) regulatory framework, Managing risks, Preparation of Financial Statements / Manage
          costs and income , Internal Operations, Administration of Company’s systems, Audits / Investigations,
          Responding to complaints, Communication, Record keeping or Cookies.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>5. Who receives your Personal Data</h2>
        <h3 className='mb-2 text-lg sm:text-xl'>
          <i>Within the Company</i>
        </h3>
        <p className='mb-6'>
          Within the Company, access to your Personal Data is given to those officers who require such access to perform
          the Company&#39;s contractual, legal obligations, and other internal activities.
        </p>
        <h3 className='mb-2 text-lg sm:text-xl'>
          <i>Outside the Company</i>
        </h3>
        <p className='mb-4'>
          Access to your Personal Data may also be given to third-party service providers and agents employed by the
          Company to enable more efficient and effective execution of its business operations, provided that an
          appropriate legal basis exists.
        </p>
        <p className='mb-4'>
          The Company may be required to share your personal information with regulatory and other authorities and
          public bodies in Cyprus and the European Union.
        </p>
        <p className='mb-4'>
          The Company may, also, share your personal information with potential or actual investors or other interested
          parties. The Company will only do this if the other parties agree to keep your Personal Data safe and private.
          In any such case, you will separately be notified in accordance with the requirements of the relevant
          regulatory framework.
        </p>
        <p className='mb-6'>
          Additionally, the Company may have a legitimate interest in transmitting your Personal Data within the Group
          for internal administrative purposes and/or a legal obligation to do so i.e. for group regulatory reporting.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>
          6. Transfer of Personal Data to third countries or international organizations
        </h2>
        <p className='mb-4'>
          The Company will only transfer your Personal Data to a country outside the EEA (a &quot;third country&quot;):
        </p>
        <ul className='mb-6 list-inside list-disc space-y-2 [&>li]:pl-4'>
          <li>
            If this is required for the execution of your orders (for example, when payments are made to a person in a
            third country, or for payment orders through a correspondent bank in a third country, such as dollar
            payments or for card services offered for example by VISA and Mastercard);
          </li>
          <li>if this is prescribed by law (for example, reporting obligations under tax law);</li>
          <li>
            in the context of data processing undertaken by third parties on behalf of the Company and where
            appropriate, on a Group level, with any of the offshore Group entities.
          </li>
        </ul>
        <p className='mb-6'>
          If the Company does transfer your Personal Data to a third country, the Company will make sure that your
          Personal Data is protected in the same way as if it was being used in the EEA.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>
          7. For how long your Personal Data is retained by the Company
        </h2>
        <p className='mb-4'>
          <i>Existing relationships</i> - The Company processes and stores your Personal Data as long as you are a
          customer of Company and/or you maintain any type of relationship with the Company.
        </p>
        <p className='mb-6'>
          <i>Terminated relationships</i> - After you stop being a customer of the Company or you stop maintaining any
          type of relationship with the Company, the Company may keep your Personal Data for a period of up to 10 (ten)
          years from the date of termination.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>
          8. Consequences for refusal to provide your Personal Data
        </h2>
        <p className='mb-6'>
          Kindly note that if you do not provide us with the Personal Data which is legitimately required, then we will
          not be allowed / or be able to commence or continue our business relationship with you or another relevant
          person.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>9. Your Rights</h2>
        <p className='mb-4'>
          Your data protection rights, granted by the GDPR, are described below. You may exercise them at any time in
          any of the following ways via e-mail.
        </p>
        <p className='mb-4'>
          <i>Right to access</i> - You have the right to obtain from the Company confirmation as to whether or not your
          Personal Data is being processed and/or obtain access to your Personal Data held by the Company.
        </p>
        <p className='mb-4'>
          <i>Right to rectification of Personal Data</i> - You have the right to question any Personal Data the Company
          holds about you that you think is wrong or incomplete. If you do, the Company will take reasonable steps to
          check its accuracy and correct it.
        </p>
        <p className='mb-4'>
          <i>Right to erasure (right to be forgotten)</i> - you have the right to have the Company delete or remove your
          Personal Data. It is clarified that the Company reserves its right to deny the said erasure if the processing
          is necessary for the Company to comply with its legal obligation, for reasons of public interest, and/or for
          the exercise of its legal claims.
        </p>
        <p className='mb-4'>
          <i>Right to restriction of processing of your Personal Data</i> - You have the right to restrict the
          Company&#39;s use of your Personal Data.
        </p>
        <p className='mb-4'>
          <i>Right to data portability</i> - You have the right to receive your Personal Data from the Company in a
          structured, commonly used and machine-readable form.
        </p>
        <p className='mb-4'>
          <i>Right to object</i> - You have the right to object to the Company&#39;s use of your Personal Data and ask
          the Company to stop using your Personal Data. There may be circumstances where you object to or ask us to
          restrict the processing of personal information, but we are legally entitled to refuse that request.
        </p>
        <p className='mb-6'>
          <i>Right to withdraw your consent</i> - Where the Company relies on your consent for the Processing of your
          Personal Data, you can withdraw your consent at any time. If you withdraw your consent, the Company may not be
          able to provide certain products or services to you. The withdrawal of the consent does not affect the
          legality of the Personal Data processed prior to the withdrawal.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>10. Filing a complaint</h2>
        <p className='mb-4'>
          For any inquiries regarding your Personal Data and your rights under the GDPR contact the Company’s DPO:
        </p>
        <p className='mb-4'>
          CAPITAVIO LIMITED
          <br />
          Terra Santa 10, Flat/Office 33, Strovolos 2001, Nicosia, Cyprus
          <br />
          C/O: DPO
          <br />
          E-mail:{' '}
          <a href='mailto:privacy@capitavio.com' className='text-primary'>
            privacy@capitavio.com
          </a>
          <br />
        </p>
        <p className='mb-6'>
          You also have the right to complain to the Office of the Commissioner for Personal Data Protection of the
          Republic of Cyprus. You can find information regarding the filing of complaints on the relevant website (
          <a href='https://www.dataprotection.gov.cy' target='_blank' className='text-primary'>
            https://www.dataprotection.gov.cy
          </a>
          ).
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>11. Changes / Amendments to the Privacy Notice</h2>
        <p className='mb-6'>
          This Privacy Notice sets out the information that the Company must provide to you for the purposes of the
          GDPR. The Company may revise or update this Privacy Notice from time to time. The new version of this Privacy
          Notice will be available on the Company&#39;s website.
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default PolicyPage
