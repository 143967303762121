import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as CookieIcon } from '../../images/landing/cookies.svg'

const CookiesPopup = () => {
  const [showBanner, setShowBanner] = useState(false)

  const closeBanner = () => {
    localStorage.setItem('capitavioCookiesBannerState', 'true')
    setShowBanner(false)
  }

  useEffect(() => {
    const bannerCookie = localStorage.getItem('capitavioCookiesBannerState')

    setShowBanner(!bannerCookie)
  }, [])

  return (
    showBanner && (
      <div className='container'>
        <div className='fixed bottom-5 left-1/2 z-10 flex h-14 w-2/3 -translate-x-1/2 items-center rounded-full bg-white p-2 shadow-lg'>
          <CookieIcon className='mr-2 h-10 w-10 text-primary' />
          <p className='mr-auto text-base'>This website uses cookies to enhance your browsing experience.</p>
          <Link to='/policy' className='mr-4 text-sm underline hover:text-primary'>
            Read Policy
          </Link>
          <button
            className='rounded-full bg-primary px-10 py-1.5 text-lg font-medium text-white transition-all hover:opacity-95'
            onClick={closeBanner}
          >
            Accept
          </button>
        </div>
      </div>
    )
  )
}

export default CookiesPopup
