import Footer from '../landing/footer'
import Header from '../landing/header'

const Terms = () => {
  return (
    <div className='flex flex-col bg-zinc-900 font-Open-Sans font-thin text-white'>
      <Header />
      <div className='container mt-32 flex flex-col'>
        <h1 className='mb-4 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:mb-8 sm:text-6xl'>
          Terms & Conditions of Use
        </h1>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>Content Right</h2>
        <p className='mb-6'>
          Your use of this Capitavio website (&quot;Website”) is subject to the following Terms of Use. By using this
          Website you signify your agreement to the Terms of Use. If you do not agree to use these Terms of Use, please
          do not use this Website. Capitavio reserves the right to change these Terms of Use from time to time without
          notice, therefore please check the Terms of Use periodically for changes. Your continued use of the Website
          will constitute acceptance of any changes to these Terms of Use. All material in whatever form contained on
          the Website (save for any trademarks owned by third parties) are the property of Capitavio Website.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>Links to other Websites</h2>
        <p className='mb-6'>
          Capitavio is not responsible for any goods or services advertised by third parties on the Website. The Website
          may also include hypertext links to third party sites. The inclusion of any link is not and does not imply an
          affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by Capitavio of any
          information contained in any third-party web site. Capitavio has no control over the content of those sites
          and does not accept any responsibility or liability in relation to those third party sites, the owners of
          which do not necessarily have any commercial or other link with Capitavio. Capitavio provides no license or
          other rights to use any third party-owned trademarks appearing on the Site.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>Purchase of Goods and Services</h2>
        <p className='mb-6'>
          Nothing appearing on this Website constitutes an offer. All information and materials published, distributed
          or otherwise made available on the Website are provided for informational purposes, for your non-commercial,
          personal use only. No information or materials published on the Website constitute a solicitation, an offer,
          or a recommendation to buy or sell any investment instruments, to affect any transactions, or to conclude any
          legal act of any kind whatsoever. In particular any order placed by you will subsequently be accepted or
          rejected entirely at the discretion of Capitavio. In considering the placing of an order your attention is
          drawn to Capitavio&#39; terms and conditions. This Website may provide links to enable you to buy products or
          services. By using this Service, you acknowledge and accept that Capitavio does not accept any responsibility
          or liability for any losses you may suffer as a result of your booking or purchasing any goods or services
          from third parties through this Website.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>The Information on this Website</h2>
        <p className='mb-6'>
          Whilst Capitavio tries to ensure the information on this Website is accurate, Capitavio is not always able to
          check the accuracy or completeness of that information. Use of the Website is at your own risk. The Website,
          together with all content, information and materials contained therein, is provided &quot;as is&quot; and
          &quot;as available&quot;, without any representations or warranties of any kind. Any materials, information or
          content accessed, downloaded, or otherwise obtained through the use of the Website is done at your own risk.
          Capitavio expressly disclaims all representations or warranties of any kind, whether express or implied, with
          respect to the Website, and all materials, information, content and functionalities contained herein. Without
          limiting the foregoing, Capitavio does not warrant the accuracy, timeliness, completeness, security,
          reliability or availability of the Website or the information or results obtained from use of the Website and
          does not guarantee continuous, uninterrupted or secure access to the Website.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>Intellectual Property Rights</h2>
        <p className='mb-6'>
          Capitavio retains all rights, title and interest (including copyrights, trademarks, patents, as well as any
          other intellectual property or other right) in all information and content (including all text, data, graphics
          and logos) on the Website. Individual pages and/or sections of the Website may be printed for personal or
          internal use only, and provided that such printouts retain all applicable copyright or other proprietary
          notices. All users must not, without limitation, modify, copy, transmit, distribute, display, perform,
          reproduce, publish, license, frame, create derivative works from, transfer or otherwise use in any other way,
          whether in whole or in part, any information, text, graphics, images, video clips, directories, databases,
          listings or software obtained from the Website for commercial or public purposes, without the prior written
          permission of Capitavio. Systematic retrieval of content from the Website to create or compile, directly or
          indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic
          devices or manual processes) without the prior written permission from Capitavio is strictly prohibited.
        </p>
        <h2 className='mb-2 text-xl font-medium sm:text-3xl'>Data Protection</h2>
        <p className='mb-6'>
          Your use of the Website and privacy is protected in accordance with our Data Protection and Privacy Policy
          Statement: Privacy Policy. By accessing or using the Website, you understand and agree that Capitavio may
          collect and retain personal or other information about you or the device you use to access the Website. You
          acknowledge and agree that if you choose to reject our cookies, some or all parts of the Website may not
          function properly or may not be accessible.
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Terms
