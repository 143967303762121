import { motion, useScroll, useTransform } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import api from '../../api/api'
import * as Yup from 'yup'

import { ReactComponent as Icon_investment } from '../../images/landing/investment.svg'
import { ReactComponent as IconInnovative } from '../../images/landing/innovative.svg'
import { ReactComponent as IconInvest } from '../../images/landing/invest.svg'
import { ReactComponent as IconSecure } from '../../images/landing/secure.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import gradient from '../../images/landing/gradient.jpg'

const features = [
  {
    icon: <IconInvest className='h-10 w-10 shrink-0 text-primary' />,
    text: 'Invest globally & effortlessly into diverse markets'
  },
  {
    icon: <IconInnovative className='h-10 w-10 shrink-0 text-primary' />,
    text: 'Innovative AI Trading solutions'
  },
  {
    icon: <Icon_investment className='h-10 w-10 shrink-0 text-primary' />,
    text: 'Investment Oriented Banking'
  },
  {
    icon: <IconSecure className='h-10 w-10 shrink-0 text-primary' />,
    text: 'Secure custody for all your assets'
  }
]

const HomeSection = () => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({ target, offset: ['end end', 'end start'] })
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.9])

  const input = useRef(null)

  const [showMessage, setShowMessage] = useState(false)

  const handleSubmit = async () => {
    try {
      await api.post(
        'signup/client-waitlist',
        JSON.stringify({ operation: 1, portalid: 8, client_mail: formik.values.email })
      )

      setShowMessage(true)
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Wrong email format').required('Email required')
    }),
    onSubmit: () => handleSubmit()
  })

  // Input autofocus when in viewport
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setTimeout(() => {
          input.current.focus()
        }, 500)
      }
    })

    observer.observe(input.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div
      id='home'
      ref={target}
      className='flex flex-col overflow-hidden bg-cover text-white'
      style={{ backgroundImage: `url(${gradient})` }}
    >
      <div className='container mb-10 mt-20 grid gap-8 sm:my-44 lg:grid-cols-2'>
        <div className='flex flex-col'>
          <div className='mb-10 flex flex-col items-start space-y-2'>
            <Logo className='text-white' />
            <span className='text-xl sm:text-2xl'>Unlock the power of your capital.</span>
          </div>
          <p className='mb-6 rounded-2xl border border-zinc-500 bg-zinc-400/25 p-4 tracking-wide backdrop-blur-lg sm:mb-8 sm:px-6 sm:py-8 xl:mb-auto'>
            Capitavio combines a unique combination of banking services, debit cards, equity trading, cryptocurrencies,
            savings, and digital assets. Embrace the Capitavio universe and let your capital work for you.
          </p>
          {!showMessage && (
            <form onSubmit={formik.handleSubmit} className='flex items-center space-x-4 sm:space-x-6'>
              <div className='relative flex h-full w-1/2 flex-col space-y-2 sm:w-2/3'>
                <input
                  id='email'
                  ref={input}
                  placeholder='Your email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='h-full rounded-full border bg-transparent px-6'
                />
                {formik.errors.email && formik.touched.email && (
                  <p className='absolute -bottom-8 left-6 text-red-500'>{formik.errors.email}</p>
                )}
              </div>
              <button
                type='submit'
                className='w-1/2 rounded-full bg-white py-3 text-lg font-medium text-black transition-colors hover:bg-primary hover:text-white sm:w-1/3'
              >
                Join Waitlist
              </button>
            </form>
          )}
          {showMessage && (
            <motion.p
              initial={{ opacity: 0, translateX: 25 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ type: 'spring', stiffness: 150, damping: 12, delay: 0, duration: 0.6 }}
              viewport={{ once: true }}
              className='mt-4 text-xl font-normal text-white'
            >
              Thank you for joining the waiting list! We will be in touch.
            </motion.p>
          )}
        </div>
        <div className='relative flex flex-col space-y-6'>
          <motion.img
            id='dashboard_img'
            src='/img/dashboard.png'
            alt='dashboard'
            initial={{ opacity: 0, translateX: 25 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ type: 'spring', stiffness: 150, damping: 12, delay: 0, duration: 0.6 }}
            viewport={{ once: true }}
            className='rounded-2xl'
          />
          <motion.div
            initial={{ opacity: 0, translateY: 50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ type: 'spring', stiffness: 150, damping: 12, delay: 0, duration: 0.6 }}
            viewport={{ once: false }}
            style={{ scale }}
            className='-bottom-24 rounded-2xl bg-gradient-to-r from-primary to-emerald-400 px-6 py-4 shadow-2xl sm:absolute sm:-right-8 sm:w-[470px] sm:px-8 sm:py-7 xl:right-6'
          >
            <div className='flex flex-col'>
              <span className='mb-3 text-xl font-semibold sm:text-2xl'>
                Prepare your Capital
                <br /> for a journey
              </span>
              <p className='mb-4 text-sm font-medium tracking-wide [text-shadow:_0_1px_16px_rgb(0_0_0_/_25%)]'>
                Maximizing the value and growth
                <br /> of your financial resources
              </p>
            </div>
            <img
              src='/img/hand_holding_phone.png'
              alt='hand_with_phone_img'
              className='absolute -right-6 bottom-0 w-[150px] sm:right-0 sm:w-[215px]'
            />
          </motion.div>
        </div>
      </div>
      <div className='bg-zinc-900'>
        <div className='container grid gap-8 py-6 font-light tracking-wide sm:grid-cols-2 lg:grid-cols-4 xl:gap-20'>
          {features.map((item, index) => (
            <div key={index} className='flex items-center space-x-3'>
              {item.icon}
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeSection
