import { useRef } from 'react'
import { HashLink } from 'react-router-hash-link'
import { motion, useScroll, useTransform } from 'framer-motion'

import { ReactComponent as Line } from '../../images/landing/features-line.svg'

const BankingSection = () => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({ target, offset: ['end end', 'start start'] })

  const scaleTop = useTransform(scrollYProgress, [0, 0.75], [0.9, 1])
  const scaleBottom = useTransform(scrollYProgress, [0, 0.75], [1, 0.9])

  return (
    <div id='banking' ref={target} className='flex scroll-m-24 flex-col bg-zinc-900 text-white'>
      <div className='container py-16 md:py-24'>
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20,
            delay: 0.15,
            duration: 2
          }}
          viewport={{ once: true }}
          className='mb-4 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:mb-8 sm:text-6xl lg:text-8xl'
        >
          Seamless Banking Experience
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20,
            delay: 0.15,
            duration: 2
          }}
          viewport={{ once: true }}
          className='mb-20 text-center text-xl sm:text-2xl lg:mb-32'
        >
          Experience Cutting-edge Digital Banking
        </motion.div>
        <div className='mb-10 grid gap-10 sm:mb-16 md:mb-20 md:grid-cols-2 md:gap-0 lg:mb-32'>
          <div className='flex flex-col space-y-6'>
            <motion.div
              style={{ scale: scaleTop }}
              className='relative flex flex-col items-start rounded-3xl bg-gradient-to-r from-primary to-emerald-400 p-6 sm:h-[275px] xl:p-8'
            >
              <img
                src='/img/phone.png'
                alt='phone'
                className='absolute bottom-0 right-2 w-[150px] sm:right-6 sm:w-[240px]'
              />
              <h3 className='mb-3 text-3xl font-semibold'>Dedicated IBANs</h3>
              <p className='mb-8 w-2/4 font-normal'>
                Set up and utilize your dedicated IBAN within seconds for ultra fast global transactions.
              </p>
              <HashLink
                to={'#home'}
                className='rounded-full bg-white px-10 py-3 text-lg font-medium text-black transition-all hover:opacity-95'
              >
                Join Waitlist
              </HashLink>
            </motion.div>
            <div className='grid gap-4 sm:gap-6 xl:grid-cols-2'>
              <motion.div
                style={{ scale: scaleTop }}
                className='relative col-span-1 flex flex-col items-start rounded-3xl bg-gradient-to-r from-white to-zinc-200 p-6 text-black xl:p-8'
              >
                <hr className='mb-4 w-1/4 border border-black lg:w-1/5 xl:mb-8'></hr>
                <h3 className='mb-3 text-2xl font-semibold leading-tight'>Virtual and Physical Debit Cards</h3>
                <p className='font-normal'>
                  Convert and utilize all your funds and spend them via your dedicated debit card.
                </p>
              </motion.div>
              <motion.div
                className='relative col-span-1 flex flex-col items-start rounded-3xl bg-gradient-to-r from-zinc-700 to-zinc-600 p-6 xl:p-8'
                style={{
                  scale: scaleTop,
                  background:
                    'radial-gradient(87% 50% at 50% -38%, hsla(0, 0%, 100%, 0.052) 77.5%, hsla(0, 0%, 100%, 0.014) 88.13%, hsla(0, 0%, 100%, 0) 100%), radial-gradient(97% 109% at 48% 0, rgba(0, 0, 0, 0.07) 0, rgba(0, 0, 0, 0.4) 100%)'
                }}
              >
                <hr className='mb-4 w-1/4 border lg:w-1/5 xl:mb-8'></hr>
                <h3 className='mb-3 text-2xl font-semibold leading-tight'>Extensive Analytics</h3>
                <p className='font-normal'>
                  Analyze and manage your spending instantly with our sophisticated, real-time analytic capabilities.
                </p>
              </motion.div>
            </div>
          </div>
          <motion.div style={{ scale: scaleTop }} className='flex flex-col items-center md:pl-8 xl:px-14'>
            <h3 className='mb-4 text-center text-3xl font-semibold tracking-wide'>Uncompromised Features</h3>
            <p className='mb-8 text-center font-light font-thin'>
              Capitavio brings to you a dedicated bank account and exclusive debit cards, ensuring secure, seamless
              transactions in a futuristic digital environment.
            </p>
            <ul className='relative flex flex-col space-y-8 font-medium tracking-wider'>
              <li className='flex space-x-6'>
                <span className='z-10 mt-1.5 h-3 w-3 shrink-0 rounded-full bg-white opacity-80'></span>
                <p>Access funds anytime, anywhere with our easy-to-use online and mobile banking services!</p>
              </li>
              <li className='flex space-x-6'>
                <span className='z-10 mt-1.5 h-3 w-3 shrink-0 rounded-full bg-white opacity-80'></span>
                <p>Withdraw cash at our partner network of worldwide ATM machines.</p>
              </li>
              <li className='flex space-x-6'>
                <span className='z-10 mt-1.5 h-3 w-3 shrink-0 rounded-full bg-white opacity-80'></span>
                <p>Experience 24/7 customer service for any questions or concerns!</p>
              </li>
              <li className='flex space-x-6'>
                <span className='z-10 mt-1.5 h-3 w-3 shrink-0 rounded-full bg-white opacity-80'></span>
                <p>
                  Direct Deposit: Enjoy the convenience of having your paycheck, government benefits, or other regular
                  income directly deposited into your account.
                </p>
              </li>
              <li className='flex space-x-6'>
                <span className='z-10 mt-1.5 h-3 w-3 shrink-0 rounded-full bg-white opacity-80'></span>
                <p>
                  Seamless Money Transfers: Instantly send and receive money with a few taps, simplifying your personal
                  and business transactions.
                </p>
              </li>
              <Line className='absolute bottom-10 left-1 text-primary' />
            </ul>
          </motion.div>
        </div>
        <motion.h2
          style={{ scale: scaleBottom }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20,
            delay: 0.15,
            duration: 2
          }}
          viewport={{ once: true }}
          className='mb-4 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:mb-8 sm:text-6xl lg:text-8xl xl:mb-24'
        >
          We offer you Debit Cards
        </motion.h2>
        <div className='flex flex-col items-center'>
          <motion.img style={{ scale: scaleBottom }} src='/img/cards.png' alt='cards' className='mb-4 sm:mb-10' />
          <motion.p
            style={{ scale: scaleBottom }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-8 text-center sm:mb-10 sm:text-xl md:w-2/3'
          >
            With our service offering both physical and virtual debit cards, effortlessly convert any asset into
            spendable currency for global transactions in seconds. Experience seamless banking, providing unparalleled
            convenience and speed for your financial needs.
          </motion.p>
          <motion.div
            style={{ scale: scaleBottom }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='rounded-full bg-white px-10 py-3 text-lg font-medium text-black transition-all hover:opacity-95'
          >
            <HashLink to={'#home'}>Join Waitlist</HashLink>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default BankingSection
