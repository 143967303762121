import { motion } from 'framer-motion'

import { ReactComponent as Icon_meaning } from '../../images/landing/meaning-of-capitavio.svg'
import { ReactComponent as Icon_mission } from '../../images/landing/mission-statement.svg'
import { ReactComponent as Icon_vision } from '../../images/landing/vision-statement.svg'

const WhyUsSection = () => {
  return (
    <div id='whyus' className='flex scroll-m-24 flex-col bg-zinc-900 text-white'>
      <div className='container my-8 grid grid-cols-12 gap-y-10 sm:my-16 lg:my-24 lg:gap-x-14 xl:gap-10'>
        <div className='col-span-12 flex flex-col justify-center lg:col-span-6 xl:col-span-4'>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-4 bg-gradient-to-tr from-sky-500 via-sky-500 to-primary bg-clip-text text-6xl font-bold leading-tight text-transparent md:text-8xl'
          >
            Why Us
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='text-lg font-normal'
          >
            We want to maximizing the value and growth of your financial resources, whether it be through investment
            opportunities, strategic planning, or innovative financial products. We show you a plan to use the untapped
            potential of your financial capabilities which is waiting to be discovered
          </motion.p>
        </div>
        <div className='relative col-span-12 flex flex-col lg:col-span-6 xl:col-span-8'>
          <img src='/img/whyus-image.png' alt='' />
          <motion.img
            src='/img/compas.png'
            alt=''
            className='absolute bottom-0 right-0 h-[200px] xl:right-0 xl:top-10 xl:h-min'
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 16,
              delay: 0.15,
              duration: 1
            }}
            viewport={{ once: false }}
          />
        </div>
        <div className='col-span-12 flex flex-col space-y-10 lg:col-span-6 xl:col-span-4'>
          <div className='flex flex-col space-y-4'>
            <div className='flex items-center space-x-4 text-primary'>
              <Icon_mission className='h-16 w-16 shrink-0 lg:h-min lg:w-min' />
              <h3 className='text-3xl font-medium leading-tight lg:text-5xl'>Mission Statement</h3>
            </div>
            <p className='text-lg font-normal leading-6'>
              Our mission is to empower you to achieve your financial goals by providing you the best financial products
              on the market, with a focus on competitive pricing, innovative technology, and exceptional customer
              journey."
            </p>
          </div>
          <div className='flex flex-col space-y-4'>
            <div className='flex items-center space-x-4 text-primary'>
              <Icon_vision className='h-16 w-16 shrink-0 lg:h-min lg:w-min' />
              <h3 className='text-3xl font-medium leading-tight lg:text-5xl'>Vision Statement</h3>
            </div>
            <p className='text-lg font-normal leading-6'>
              Our vision is to become the leading financial portal that provides access to a comprehensive range of
              financial products and services, with a commitment to delivering the best value, convenience, and customer
              experience in the market.
            </p>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-6 xl:col-span-8'>
          <motion.div
            initial={{ opacity: 0, translateY: 50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 16,
              delay: 0.15,
              duration: 1
            }}
            viewport={{ once: false }}
            className='sticky top-24 flex flex-col space-y-4'
          >
            <div className='flex items-center space-x-4 text-primary'>
              <Icon_meaning className='h-16 w-16 shrink-0 lg:h-min lg:w-min' />
              <h3 className='text-3xl font-medium leading-tight lg:text-5xl xl:w-1/3'>Meaning of Capitavio</h3>
            </div>
            <p className='text-lg font-normal leading-6'>
              We created this phantasy word as a combination from the words „Capital“ and „avio“ (spanish: prepare for a
              journey) in a catchy nice sounding way. Capitavio means in a figuratively way „Prepare your Capital for a
              journey“ - the journey of your life where we adjust our solutions to your changing needs for your capital.
              "The path for your capital" generally means the strategy or plan for investing or deploying your financial
              resources to achieve your financial goals, whether it be for personal wealth building or business growth.
              It implies that there is a well-defined route or direction for your capital to take, with a clear
              understanding of the risks and potential returns involved. We strive to empower individuals and businesses
              of all sizes to achieve their financial goals and aspirations, and to build a brighter, more prosperous
              future for all.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default WhyUsSection
