import { useRef } from 'react'
import { HashLink } from 'react-router-hash-link'
import { motion, useScroll, useTransform } from 'framer-motion'

import { ReactComponent as Bitcoin } from '../../images/landing/bitcoin.svg'
import background from '../../images/landing/cryptocurrency-background.png'

const ProductsSection = () => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({ target, offset: ['end end', 'start start'] })

  const scaleTop = useTransform(scrollYProgress, [0, 0.75], [0.9, 1])
  const scaleBottom = useTransform(scrollYProgress, [0, 0.75], [1, 0.9])

  return (
    <div id='products' ref={target} className='flex scroll-m-24 flex-col bg-zinc-100'>
      <div className='container py-10 sm:py-16 lg:py-24'>
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20,
            delay: 0.15,
            duration: 2
          }}
          viewport={{ once: true }}
          className='mb-8 text-center text-3xl font-bold sm:text-4xl lg:text-6xl xl:text-8xl'
        >
          Benefit from the entire World of digital Assets
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20,
            delay: 0.15,
            duration: 2
          }}
          viewport={{ once: true }}
          className='mb-16 text-center text-lg font-medium sm:text-xl lg:text-2xl xl:mb-32'
        >
          Our vast range of product offerings - one app for all your needs
        </motion.div>
        <div className='grid grid-cols-12 gap-4 sm:gap-6'>
          <motion.div
            style={{ scale: scaleTop }}
            className='relative col-span-12 flex flex-col items-start justify-end overflow-hidden rounded-3xl bg-white p-6 md:col-span-6 lg:h-[450px] lg:p-10'
          >
            <img src='/img/refresh.png' alt='refresh' className='absolute right-0 top-0 h-[170px] lg:h-min' />
            <motion.img
              initial={{ opacity: 0, scale: 0.25 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', stiffness: 150, damping: 16, delay: 0.1, duration: 0.5 }}
              viewport={{ once: false }}
              src='/img/refresh-badge.png'
              alt='refresh-badge'
              className='absolute bottom-2 right-0 h-28 lg:right-32 lg:top-4 lg:h-min'
            />
            <h3 className='mb-3 text-3xl font-semibold'>ETF'S</h3>
            <p className='mb-8 w-3/4 font-normal'>
              Experience the diversity of the ETF market effortlessly with our simple tools for managing a wide range of
              funds.
            </p>
            <HashLink
              to={'#home'}
              className='rounded-full bg-primary px-10 py-3 text-lg font-medium text-white transition-all hover:opacity-95'
            >
              Join Waitlist
            </HashLink>
          </motion.div>
          <motion.div
            style={{ scale: scaleTop }}
            className='relative col-span-12 flex flex-col items-start justify-start overflow-hidden rounded-3xl bg-gradient-to-br from-primary via-primary to-emerald-400 p-6 text-white md:col-span-6 lg:h-[450px] lg:p-10'
          >
            <motion.img
              src='/img/metals-phone.png'
              alt='mobile_app'
              className='absolute -right-12 bottom-0 h-[180px] lg:-right-16 lg:h-min'
              initial={{ opacity: 0, translateY: 50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ type: 'spring', stiffness: 150, damping: 12, delay: 0.25, duration: 0.5 }}
              viewport={{ once: false }}
            />
            <motion.img
              src='/img/metals-card.png'
              alt='gold_card'
              className='absolute -bottom-10 -right-12 h-[150px] lg:-bottom-16 lg:-right-16 lg:h-min'
              initial={{ scale: 0.5, translateY: 100 }}
              whileInView={{ scale: 1, translateY: 0 }}
              transition={{ type: 'spring', stiffness: 150, damping: 16, delay: 0.25, duration: 0.75 }}
              viewport={{ once: false }}
            />
            <h3 className='mb-3 text-3xl font-semibold'>Precious Metals</h3>
            <p className='mb-8 w-3/4 font-normal'>
              Embrace the lasting value of precious metals with our accessible platform, simplifying your journey into
              gold, silver, and more.
            </p>
            <HashLink
              to={'#home'}
              className='rounded-full bg-white px-10 py-3 text-lg font-medium text-black transition-all hover:opacity-95'
            >
              Join Waitlist
            </HashLink>
          </motion.div>
          <div
            className='col-span-12 h-[250px] overflow-hidden rounded-3xl bg-white bg-cover bg-center bg-no-repeat md:col-span-7 md:h-full xl:col-span-8'
            style={{ backgroundImage: `url(${background})` }}
          ></div>
          <motion.div className='relative col-span-12 flex flex-col items-start overflow-hidden rounded-3xl bg-white p-6 md:col-span-5 lg:p-10 xl:col-span-4 xl:h-[450px]'>
            <Bitcoin className='absolute -bottom-12 -right-10 h-32 w-32 text-zinc-100 lg:-bottom-12 lg:-right-12 lg:h-64 lg:w-64' />
            <h3 className='mb-3 text-3xl font-semibold'>Cryptocurrencies</h3>
            <p className='mb-8 font-normal'>
              Join the crypto wave with our easy-to-use platform, making buying, selling and holding digital currencies
              a breeze.
            </p>
            <HashLink
              to={'#home'}
              className='rounded-full bg-primary px-10 py-3 text-lg font-medium text-white transition-colors hover:bg-primary hover:text-white'
            >
              Join Waitlist
            </HashLink>
          </motion.div>
          <motion.div
            style={{ scale: scaleBottom }}
            className='relative col-span-12 flex flex-col items-start rounded-3xl bg-white p-6 md:justify-center lg:h-[450px] lg:p-10'
          >
            <h3 className='mb-6 text-3xl font-semibold'>Stocks</h3>
            <p className='mb-8 font-normal sm:w-1/2 md:w-1/3'>
              Step into the world of stocks with our seamless services, making it easy to be part of the corporate
              growth story.
            </p>
            <HashLink
              to={'#home'}
              className='rounded-full bg-primary px-10 py-3 text-lg font-medium text-white transition-colors hover:bg-primary hover:text-white'
            >
              Join Waitlist
            </HashLink>
            <img
              src='/img/stocks-phone.png'
              alt='stocks_phone'
              className='mt-10 sm:absolute sm:-bottom-6 sm:right-2 sm:mt-0 sm:h-full md:-bottom-8 md:right-10'
            />
            <img
              src='/img/stocks-cpi.png'
              alt='stocks_cpi'
              className='absolute right-8 top-48 h-24 w-24 md:right-2 md:top-2 lg:right-[600px] lg:top-12'
            />
            <img src='/img/stocks-tsla.png' alt='stocks_cpi' className='absolute bottom-10 right-10 h-40 w-40' />
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default ProductsSection
